import React from "react";
import "./InvalidId.scss";

import Error from "../Images/icon-Failure.png";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

function ServerErrorScreen({ backToScan }) {
  return (
    <div className="invalid__base" onClick={backToScan}>
      <div className="invalid__title">
        <h1 className="invalid__title__text">
          Error - Internal server issue. Tap to retry.
        </h1>
      </div>
      <div className="invalid__image">
        <img className="invalid__image__logo" src={Error} alt="Tick" />
      </div>
      <div className="invalid__footer">
        <img className="invalid__footer__logo" src={VenueNow} alt="VenueNow" />
      </div>
    </div>
  );
}

export default ServerErrorScreen;
