import { React, useState, useEffect } from "react";

import axios from "axios";

import ScanContainerScreen from "./Components/ScanContainerScreen";
import ContainerScanned from "./Components/ContainerScanned";

import PackageNotFound from "./Components/PackageNotFound";
import PackageNotForVenue from "./Components/PackageNotForVenue";
import PackageAlreadyScanned from "./Components/PackageAlreadyScanned";
import GenericErrorScreen from "./Components/GenericErrorScreen";
import ServerErrorScreen from "./Components/ServerErrorScreen";

import Sfx from "./Sounds/Sfx.mp3";
import FinishBell from "./Sounds/FinishBell.mp3";
import Error from "./Sounds/error.mp3";
import useSound from "use-sound";

function EcoBin() {
  const [packageData, setPackageData] = useState("");
  const [activePage, setActivePage] = useState("ScanPackagePage");

  const token = localStorage.getItem("token");

  const [play] = useSound(Sfx);
  const [playFinish] = useSound(FinishBell);
  const [playError] = useSound(Error);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    if (activePage === "Finish") {
      finish();
    }
    if (activePage === "PackageScanned") {
      handleSubmit();
    }
  }, [activePage]);

  const activateScannedPage = () => {
    setActivePage("PackageScanned");
  };
  const backHome = () => {
    play();
    setPackageData("");
    setActivePage("ScanPackagePage");
  };

  const finish = () => {
    playFinish();
    setTimeout(() => {
      setPackageData("");
      setActivePage("ScanPackagePage");
    }, 800);
  };

  const handleSubmit = async () => {
    const loginFormData = {
      packagingItem: packageData,
    };

    try {
      const response = await axios({
        method: "post",
        url: "https://api.venuenow.tech/packaging-transaction/ecobin/checkin",
        data: loginFormData,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      if (response.data.statusCode === 201) {
        playFinish();
        setActivePage("Finish");
      }
      if (response.data.statusCode === 500) {
        setActivePage("PackageNotForVenue");
        playError();
      }
    } catch (error) {
      let errorStatusResult = error.response.status.toString();
      const startsWithFive = errorStatusResult.startsWith("5");

      if (errorStatusResult === "404") {
        setActivePage("PackageNotFound");
        playError();
      } else if (errorStatusResult === "409") {
        setActivePage("PackageAlreadyScanned");
        playError();
      } else if (startsWithFive) {
        setActivePage("ServerErrorScreen");
        playError();
      } else {
        setActivePage("GenericErrorScreen");
        playError();
      }
    }
  };

  return (
    <div className="body">
      <form className="body">
        {activePage === "ScanPackagePage" && (
          <ScanContainerScreen
            setPackageData={setPackageData}
            setActivePage={activateScannedPage}
          />
        )}

        {activePage === "PackageNotFound" && (
          <PackageNotFound backToScan={backHome} />
        )}
        {activePage === "PackageNotForVenue" && (
          <PackageNotForVenue backToScan={backHome} />
        )}
        {activePage === "PackageAlreadyScanned" && (
          <PackageAlreadyScanned backToScan={backHome} />
        )}
        {activePage === "GenericErrorScreen" && (
          <GenericErrorScreen backToScan={backHome} />
        )}
        {activePage === "ServerErrorScreen" && (
          <ServerErrorScreen backToScan={backHome} />
        )}

        {activePage === "Finish" && <ContainerScanned />}
      </form>
    </div>
  );
}

export default EcoBin;
