import "./ContainerScanned.scss";

import VenueNow from "../Images/logo-rectangle-venuenow.png";
import Tick from "../Images/icon-Success.png";

function ContainerScanned() {
  return (
    <div className="scanned__base">
      <div className="scanned__title">
        <h1 className="scanned__title__text">Container sucessfully scanned!</h1>
      </div>
      <div className="scanned__image">
        <img className="scanned__image__logo" src={Tick} alt="Tick" />
      </div>

      <div className="scanned__footer">
        <img className="scanned__footer__logo" src={VenueNow} alt="VenueNow" />
      </div>
    </div>
  );
}

export default ContainerScanned;
